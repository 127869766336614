/** GVF functions */
var gvf = function(){
	var tTimeout = [];
	var loadedJs = {};

	/**
	 * Gets JSON from object
	 * @param {*} obj
	 * @returns {string}
	 */
	function toJson(obj){
		if($.isPlainObject(obj) || $.isArray(obj))
			return JSON.stringify(obj);
		else 
			return obj;	
	}

	/**
	 * Reads json string
	 * @param {string} obj
	 * @returns {Object}
	 */
	function parseJson(obj){
		if(obj && obj.indexOf && (obj.indexOf("{")>-1 || obj.indexOf("[")>-1)){
			try{
				return JSON.parse(obj);
			}catch(e){
				return obj;
			}
		}else
			return obj;
	}

	/**
	 * Loads given script
	 * @param {string} jsUrl
	 * @param {function} returnFunction
	 */
	function loadJsScript(jsUrl,returnFunction){
		if(!loadedJs[jsUrl]){
            var script = document.createElement("script");
			script.setAttribute("type","text/javascript");
            script.onload = function(){
                loadedJs[jsUrl] = true;
                if(returnFunction){
                    returnFunction();
				}
            };
			script.setAttribute("src",jsUrl);
			document.getElementsByTagName("head")[0].appendChild(script);
		}else{
			if(returnFunction){
                returnFunction();
			}
		}
	}

	function parseUrlParams(params){
		if(params.length>0){
			var assigns = params.split("&");
			var firstParam = assigns[0].replace('?','');
			assigns.shift();
			assigns.push(firstParam);
			var object = {};
			for(var i=0;i<assigns.length;i++){
				var assign = assigns[i].split("=");
				object[assign[0]] = decodeURIComponent(assign[1]);
			}
			return object;
		}else
			return {};
	}

	/**
	 * Calls a remote endpoint
	 * @param {string} remoteFunction
	 * @param {Array} params
	 * @param {function?} returnFunction
	 * @return {Object}
	 */
	function endpoint(remoteFunction,params,returnFunction){
		return endpointCall(remoteFunction,params,true,returnFunction);
	}

	/**
	 * Run the given function synchronously in server
	 * @param {string} remoteFunction
	 * @param {Array} params
	 * @return {Object}
	 */
	function endpointSync(remoteFunction,params){
		return endpointCall(remoteFunction,params,false);
	}

	function endpointCall(remoteFunction,params,async,returnFunction){
		var responseFunction = function(response){
			return gvf.parseJson(response);
		};
		var ret = $.ajax(
			{
				type: 'POST',
				async: async,
				url: (gvf.config.rootUrl()?gvf.config.rootUrl():"")+"/endpoint.gvf?"+Math.random(),
				data: {"func":remoteFunction,"params":gvf.toJson(params),"url":window.location.toString()}
			}
		);
		if(!async)
			return responseFunction(ret.responseText);
		else{
			ret.done(
				function(data){
					if(returnFunction)
						returnFunction(responseFunction(data));
				}
			);
			ret.fail(
				function(){
					if(returnFunction)
						returnFunction({"status":"fail"});
				}
			);
			return ret;
		}
	}

	/**
	 * Delays execution and clears timeouts if they overlap
	 * @param {int} milliseconds
	 * @param {function} returnFunction
	 * @param {string} key
	 * @return {int} SetTimeout value returned
	 */
	function delay(milliseconds,returnFunction,key){
		if(!key)
			key = returnFunction;
		
		if(tTimeout[key])
			clearTimeout(tTimeout[key]);
		tTimeout[key] = setTimeout(
			function(){
				returnFunction();
			},
			milliseconds
		);
		return tTimeout[key];
	}
	
	return {
		"toJson":toJson,
		"parseJson":parseJson,
		"loadJsScript":loadJsScript,
		"endpoint":endpoint,
		"endpointSync":endpointSync,
		"delay":delay,
        "parseUrlParams":parseUrlParams
	};
}();