var ui = function(){
	var funcInit = [];
	var initExecuted = false;
	
	function ready(jqSelector,f){
		if(jqSelector.indexOf(",")==-1){
			var func = {"func":f,"jqSelector":jqSelector};
			funcInit.push(func);
			if(initExecuted)
				initBase(null,func);
		}else{
			var jqSels = jqSelector.split(",");
			for(var i=0;i<jqSels.length;i++)
				ready(jqSels[i],f);
		}
	}
	
	function readyBody(f){
		ready("body",f);
	}
	
	function initBase(jq,funcExec){
		if(jq && jq.length==0)
			return;
		
		var functions = null;
		if(funcExec)
			functions = [funcExec];
		else
			functions = funcInit;

		if(!jq)
			jq = $("body");

		var isBody = jq.is("body");
		var emptySelectors = [];
		var cItems = {};
		for(var i=0;i<functions.length;i++){
			var func = functions[i].func;
			var jqSel = functions[i].jqSelector;
			if(func){
				if(cItems[jqSel]){
					var itemsC = cItems[jqSel];
					for(var jc=0;jc<itemsC.length;jc++)
						func(itemsC[jc]);
					
				}else{
					var noItems = false;
					for(var j=0;j<emptySelectors.length;j++){
						var selV = emptySelectors[j];
						if(jqSel.length>selV.length && jqSel.indexOf(selV)===0 && "abcdefghijklmnopqrstuvwxyz-_".indexOf(jqSel.charAt(selV.length).toLowerCase())==-1){
							noItems = true;
							break;
						}
					}
					if(noItems)
						continue;
					var jqF = null;
					if(jq.is(jqSel))
						jqF = jq.filter(jqSel);
					else
						jqF = jq.find(jqSel);
					
					var items = [];
					if(jqF.length>0){
						jqF.each(
							function(){
								var item = $(this);
								items.push(item);
								func(item);
							}
						);
						if(items.length==0)
							emptySelectors.push(jqSel);
					}else if(!isBody){
						var jqF2 = $(jqSel);
						jqF2.each(
							function(){
								if(jq.find(this).length>0){
									var item = $(this);
									func(item);
									items.push(item);
								}
							}
						);
					}
					cItems[jqSel] = items;
				}
			}
		}
	}
	
	/**
	 * Initializes DOM
	 * @param {jQuery} jq
	 */
	function init(jq){
		initBase(jq);
	}

    //Gets values from form
    function getValues($form){
        if($form.is("form")){
            //move away nested forms
            var $extract = $("<div />");
            var $itemsToExtract = $form.find(".js-form");
            $itemsToExtract.each(
                function(){
                    $(this).data("parent",$(this).parent());
                    $(this).data("prev",$(this).prev());
                }
            );
            $extract.append($itemsToExtract);

            var values = $form.serializeArray();
            var formData = {};
            for(var i=0;i<values.length;i++){
                var nom = values[i].name;
                var val = gvf.parseJson(values[i].value);
                if(nom.substring(nom.length-2)=="[]"){
                    var k = nom.substring(0,nom.length-2);
                    if(!formData[k]){
                        formData[k] = [];
					}
                    formData[k].push(val);
                }else{
                    formData[nom] = val;
				}
            }

            //restore nested forms
            $extract.find(".js-form").each(
                function(){
                    var $item = $(this);
                    if($item.data("prev").length>0)
                        $item.data("prev").after($item);
                    else
                        $item.data("parent").prepend($item);
                }
            );

            return formData;
        }else
        	return null;
    }

    //Report validity on form
    function reportValidity($form){
		if($form.is("form")){
            return $form.get(0).reportValidity();
		}else{
			return null;
		}
    }
	
	$(document).ready(
		function(){
			init();
			initExecuted = true;
		}
	);

    //extend functionality for forms
    jQuery.fn.extend(
        {
            "getValues":function(){
                return getValues(this);
            },
            "reportValidity":function(){
                return reportValidity(this);
            }
        }
    );

	return {
		"ready":ready,
		"readyBody":readyBody,
		"init":init
	};
}();