/** GVF Config */
gvf.config = function(){
	var rootUrlV = null;

	/**
	 * Sets/gets root url
	 * @param {string} rootUrl
	 * @return string
	 */
	function rootUrl(rootUrl){
		if(rootUrl===undefined)
			return rootUrlV;
		else
            rootUrlV = rootUrl;
	}
	
	return {
		"rootUrl":rootUrl
	};
}();